import './src/styles/global.css'
// @ts-ignore
import React from "react";
import {Auth0Provider} from '@auth0/auth0-react';


export const wrapRootElement = ({element}) => (
    <Auth0Provider
        cacheLocation="localstorage"
        domain="dev-tp6gd71kynbz1v5s.us.auth0.com"
        clientId="mK0v3GLIedZgXO3MrEW9MbAJzgNN9Jlp"
        authorizationParams={{
            redirect_uri: window.location.origin
        }}
    >

        {element}</Auth0Provider>
)