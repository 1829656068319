exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-demo-tsx": () => import("./../../../src/pages/demo.tsx" /* webpackChunkName: "component---src-pages-demo-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-integrator-tsx": () => import("./../../../src/pages/integrator.tsx" /* webpackChunkName: "component---src-pages-integrator-tsx" */),
  "component---src-pages-owner-tsx": () => import("./../../../src/pages/owner.tsx" /* webpackChunkName: "component---src-pages-owner-tsx" */),
  "component---src-pages-support-knowledge-base-tsx": () => import("./../../../src/pages/support/knowledge-base.tsx" /* webpackChunkName: "component---src-pages-support-knowledge-base-tsx" */),
  "component---src-pages-support-policy-tsx": () => import("./../../../src/pages/support/policy.tsx" /* webpackChunkName: "component---src-pages-support-policy-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-support-videos-tsx": () => import("./../../../src/pages/support/videos.tsx" /* webpackChunkName: "component---src-pages-support-videos-tsx" */),
  "component---src-pages-wxs-builder-tsx": () => import("./../../../src/pages/wxs-builder.tsx" /* webpackChunkName: "component---src-pages-wxs-builder-tsx" */),
  "component---src-templates-kb-articles-tsx": () => import("./../../../src/templates/kbArticles.tsx" /* webpackChunkName: "component---src-templates-kb-articles-tsx" */),
  "component---src-templates-kb-categories-tsx": () => import("./../../../src/templates/kbCategories.tsx" /* webpackChunkName: "component---src-templates-kb-categories-tsx" */),
  "component---src-templates-modules-js": () => import("./../../../src/templates/modules.js" /* webpackChunkName: "component---src-templates-modules-js" */),
  "component---src-templates-resources-tsx": () => import("./../../../src/templates/resources.tsx" /* webpackChunkName: "component---src-templates-resources-tsx" */)
}

